import webLogo from './pet.png';
import mobileLogo from './mobile.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <img src={webLogo} className="web-logo" alt="web logo" />
      <img src={mobileLogo} className="mobile-logo" alt="mobile logo" />
    </div>
  );
}

export default App;